
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import ActivitesTickets from "@/views/still/deploy/activitesTickets.vue";
import SyntheseProjet from "@/views/still/deploy/syntheseProjet.vue";
import StockRefs from "./stockRefs.vue";

export default defineComponent({
  name: "customer-details",
  components: {
    ActivitesTickets,
    SyntheseProjet,
    StockRefs,
  },
  setup() {

    const router = useRouter();

    const affEquipByType = (type) => {
      router.push({  name: "listEquipementbyType", params: { code: type } });
    }

    const state = reactive({
      loaderEnabled: true,
    });
    const { t } = useI18n()

    const Plur = (item) => {
      if (item>0) return t('s');
    }

    onMounted(async () => {
      state.loaderEnabled = false;
    });

    return {
      state,
      affEquipByType,
      Plur,
      model: {}
    };
  },
});
