
import { defineComponent, onMounted, reactive } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "kt-activite",
  props: {
    widgetClasses: String,
  },
  setup() {
    const state = reactive({
      list: [],
      loaderEnabled: true,
    });
    onMounted(async () => {
      MenuComponent.reinitialization();
      let rEch = await mAxiosApi.prototype.getAxios("/getMAJTickets");
      state.list = rEch.results;
      state.loaderEnabled = false;
    });

    return {
      state,
    };
  },
});
